.notice_board_container {
  width: 100%;

  .notice_head {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    form {
      display: flex;
      align-items: center;
      width: 80%;
      height: 50px;
      gap: 20px;
      .search_box {
        height: 100%;
        display: flex;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 10px;
        .search_type_box {
          border-right: 1px solid #ccc;
          display: flex;
          align-items: center;
          padding-right: 8px;
          .search_types {
            display: flex;
            gap: 10px;
            input {
              margin-right: 3px;
            }
          }
        }
        .searchText {
          border: none;
          padding: 10px;
          font-size: 14px;
          margin-right: 5px;
          &:focus {
            outline: none;
          }
        }
        .submitBtn {
          height: 100%;
          border-radius: 10px;
          background: #4880ff;
          color: #fff;
          border: none;
          font-size: 14px;
          font-weight: 60000;
          padding: 0 20px;
        }
      }
      .search_date {
        height: 100%;
        input {
          border: 1px solid #ccc;
          border-radius: 10px;
          height: 100%;
          padding: 10px;
          font-size: 14px;
          &:nth-of-type(1) {
            margin-left: 5px;
          }
        }
      }
      .etcBtnBox {
        position: relative;
        width: 50px;
        height: 50px;
        cursor: pointer;
        .etcBtn {
          position: relative;
          width: 20px;
          height: 100%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px; /* 둥근 모서리 */
          transition: background-color 0.3s;

          > span {
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: #333;
            border-radius: 50%;
            top: 50%; /* 중간 점 위치 */
            transform: translateY(-50%);
            &::after,
            &::before {
              content: "";
              position: absolute;
              width: 5px;
              height: 5px;
              background-color: #333;
              border-radius: 50%;
            }
            &::before {
              top: 9px; /* 첫 번째 점 위치 */
            }
            &::after {
              bottom: 9px; /* 세 번째 점 위치 */
            }
          }
        }
        &:hover .btns {
          opacity: 1;
          visibility: visible;
          pointer-events: auto; /* 클릭 가능하게 */
          transition: opacity 0.3s, visibility 0s;
        }
        p {
          width: 100%;
          height: 100%;
          line-height: 50px;
          background-color: #ccc;
          padding: 0 10px;
        }
        .btns {
          font-size: 12px;
          position: absolute;
          z-index: 5;
          width: 100px;
          left: 20px;
          top: 60%;
          border: 1px solid #ccc;
          background-color: #fff;
          padding: 10px;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          pointer-events: none; /* 클릭 방지 */
          transition: opacity 0.3s, visibility 0s 0.3s;
        }
      }
    }

    .newBtn {
      margin-left: auto;
      height: 100%;
      border-radius: 5px;
      background: #4880ff;
      color: #fff;
      border: none;
      font-size: 14px;
      font-weight: 600;
      padding: 0 20px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #525252;
    overflow: hidden;
    thead {
      background: #ededed;
      top: 4px;
      z-index: 3;
      width: 101%;
    }
    tr {
      width: 100%;
      cursor: pointer;
      &.notice_tr {
        background-color: #fffcf3;
        font-weight: 600;
      }
      th {
        cursor: default;
        height: 45px;
        line-height: 45px;
        &:nth-of-type(1) {
          width: 10%;
        }
        &:nth-of-type(2) {
          width: 50%;
        }
        &:nth-of-type(3) {
          width: 10%;
        }
        &:nth-of-type(4) {
          width: 15%;
        }
        &:nth-of-type(4) {
          width: 15%;
        }
      }
      td {
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid #f5f5f5;
        position: relative;

        img {
          position: absolute;
          height: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-35%, -55%);
        }
        &.notice_title {
          text-align: left;
        }
      }
    }
  }
}

.notice_detail_container {
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
  .titleBox {
    margin-bottom: 25px;
  }
  input[type="text"],
  textarea {
    &:focus {
      border: none;
      outline: none;
      color: #555;
    }
  }
  .detail_head {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;

    .detail_title {
      font-size: 24px;
      border: none;
      font-weight: 600;
      border: none;
      width: 100%;
      padding: 15px 0;
      background: none;
    }
    .detail_type {
      font-size: 16px;
      label {
        margin-right: 5px;
        color: #555;
      }
      select {
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 5px;
      }
    }
    .disabled {
      display: flex;
      align-items: center;
      color: #a0a4a8;
      font-size: 14px;

      input {
        border: none;
        color: #a0a4a8;
      }
    }
  }

  .detail_content {
    width: 100%;
    height: calc(100% - 200px);
    font-size: 15px;
    line-height: 20px;
    color: #555;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    &.editing {
      border-top: 1px solid #ccc;
      .ql-toolbar {
        display: none;
      }
    }
    .ql-toolbar {
      position: sticky;
      z-index: 5;
      top: 0;
      left: 0;
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #ccc;
    }
    .ql-container {
      padding: 15px 10px 10px 10px;
      border: none;
      .ql-editor {
        overflow-y: hidden;
      }
    }
  }

  .edit-buttons {
    position: fixed;
    top: 295px;
    right: 100px;
    display: flex;
    gap: 10px;
    button {
      height: 50px;
      border-radius: 5px;
      background: #003181;
      color: #fff;
      border: none;
      font-size: 14px;
      font-weight: 600;
      padding: 5px 10px;
      &.back-to-list {
        background-color: #3f72af; /* 차분한 파란색 */
        color: white;
        border: 1px solid #2c5f91; /* 어두운 파란색 테두리 */
      }

      // &.save {
      //   background-color: #4caf50; /* 초록색 */
      //   border: 1px solid #388e3c; /* 초록색 테두리 */
      // }

      &.delete {
        background-color: #ff3d00; /* 레드 */
        border: 1px solid #d32f2f; /* 레드 테두리 */
      }

      &.cancel {
        background-color: #888888; /* 회색 */
        border: 1px solid #616161; /* 어두운 회색 테두리 */
      }

      &.edit,
      &.save {
        background-color: #4880ff; /* 파란색 */
        border: 1px solid #357ae8; /* 파란색 테두리 */
      }
      &:hover {
        opacity: 95%;
      }
    }
  }
}
