.tmWorkStatus_container {
  /*드롭다운공통*/
  .dropdown-container,
  .dropdown {
    .dropdown-header {
      height: 100%;
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      background: #fff;
      caret-color: transparent; /* 깜빡이는 커서 숨기기 */
    }

    .dropdown-arrow {
      font-size: 12px;
    }

    .dropdown-list {
      position: absolute;
      top: 115%;
      left: 0;
      width: 100%;
      background: #fff;
      max-height: 200px;
      overflow-y: auto;
      z-index: 3;
      border-radius: 10px;
      border: 1px solid #efefef;
      background: #fff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }

    .dropdown-item,
    .dropdown-option,
    .dropdown-search {
      font-size: 16px;
      text-align: left;
      height: 45px;
      line-height: 45px;
      padding-left: 10px;
      cursor: pointer;
      border-bottom: 1px solid #d9d9d9;
      color: #222;
    }

    .dropdown-item:hover,
    .dropdown-option:hover {
      background: #f1f1f1;
    }
    .react-datepicker {
      position: absolute;
      left: 36%;
      top: 100%;
    }
  }

  .tmWorkStatus_filter {
    display: flex;
    gap: 20px;
    > div {
      height: 50px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 30%;
      }
    }
    .date_filter_box {
      width: 50% !important;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      cursor: pointer;
    }
    .date-navigation {
      border: 1px solid #ccc;
      background: #fff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 4% 0 2%;
      gap: 5px;
      .arrow-icon {
        cursor: pointer;
        // background-color: goldenrod;
        height: 100%;
        padding: 23% 20%;
        box-sizing: border-box;
        color: #979797;
      }
    }
    .dropdown-container {
      width: 55%;
      height: 50px;
      &.date {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ccc;
        background: #fff;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-left: 20px;
        .selectedDate {
          width: 100%;
          height: 100%;
          line-height: 50px;
          flex: 2 1;
          text-align: center;
          border-left: 1px solid #ccc;
          // padding-left: 5%;
        }
        .dropdown-header {
          width: 120px;
          border: none;
          display: flex;
          justify-content: center;
          padding: 0;
          gap: 10px;
        }
        .dropdown-list {
          width: 150px;
        }
        .dropdown-arrow {
          display: none;
        }
      }

      // &.hospital {
      //   width: 30%;
      //   margin-right: 1%;
      // }
      // &.manager {
      //   width: 10%;
      // }
    }
  }
  .tmStatusCounts_container {
    margin: 30px 0 40px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 3%;
    ul {
      flex: 1;
      &.groupedCounts {
        flex: 2.5;
      }
      height: 200px;
      border-top: 8px solid #4880ff;
      background: #fff;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      box-shadow: 0px 10px 30px rgba(207, 207, 207, 0.5);
      li {
        h4 {
          color: #202224;
          font-size: 22px;
          font-weight: 700;
        }
        &.cancellationsCount {
          p {
            color: #ea0234;
          }
        }
        &.absenceCount {
          p {
            color: #868686;
          }
        }
        p {
          color: #004fc6;
          font-size: 22px;
          font-weight: 700;
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          span {
            font-size: 60px;
          }
        }
      }
    }
  }
  .searchBox {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    margin-bottom: 22px;
    .dropdown-container {
      width: 10%;
      height: 100%;
      &.searchField {
        position: relative;
      }
    }
    .input-container {
      width: 30%;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px 5px 15px;
      input {
        height: 100%;
        width: 85%;
        border: none;
        font-size: 16px;
        &:focus {
          border: none;
          outline: none;
        }
      }
      button {
        width: 54px;
        height: 27px;
        border-radius: 5px;
        background: #4880ff;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        border: none;
      }
    }
  }
  .tm_table {
    width: 100%;
    border-collapse: collapse;
    thead {
      height: 50px;
      background: #ededed;
      th {
        font-weight: 500;
        color: #202224;
        font-size: 14px;
        text-align: left;
        height: 100%;
        &:first-child {
          width: 15%;
          padding-left: 3%;
        }
        &.follow_up_time {
          height: 50px;
          display: flex;
          align-items: center;
          gap: 5px;
          .sortBtn {
            border: none;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    tbody {
      tr {
        height: 70px;
        td {
          color: #202224;
          font-size: 14px;
          font-weight: 300;
          border-bottom: 1px solid rgba(151, 151, 151, 0.3);
          &:first-child {
            width: 15%;
            padding-left: 3%;
          }
          span {
            display: inline-block;
            text-align: center;
            font-weight: bold;
            width: 80%;
            padding: 5% 10%;
            border-radius: 20px;
            &.default-status {
              background: rgba(151, 151, 151, 0.2);
              color: #979797;
            }
            &.red-status {
              background: rgba(255, 0, 0, 0.2);
              color: rgba(255, 0, 0, 1);
            }

            &.yellow-status {
              color: rgba(255, 149, 0, 1);
              background: rgba(255, 149, 0, 0.2);
            }

            &.blue-status {
              color: #4880ff;
              background: rgba(72, 128, 255, 0.2);
            }
          }
        }
      }
    }
  }
  .excel-button {
    position: fixed;
    z-index: 2;
    bottom: 65px;
    right: 45px;
    width: 80px;
    height: 80px;
    background-color: #ffffff; /* 배경색 흰색 */
    border: none;
    cursor: pointer;
    border-radius: 50%; /* 버튼을 원형으로 만들기 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
  }
}
