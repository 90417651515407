.tm-calendar {
  width: 100%;
  .calendar-filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: 50px;
    // .hospital-filter {
    //   position: relative;
    //   width: 25%;
    //   height: 100%;
    //   border-radius: 10px;
    //   border: 1px solid #d9d9d9;
    //   background: #fff;
    //   display: flex;
    //   align-items: center;
    //   padding: 0 15px;
    //   .hospital-custom-select {
    //     width: 100%;
    //     height: 100%;
    //     border: none;
    //     background: none;
    //     font-size: 16px;
    //     color: #333;
    //     cursor: pointer;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     .dropdown-icon {
    //       color: #707070;
    //       font-size: 20px;
    //     }
    //   }
    //   .custom-options {
    //     position: absolute;
    //     top: 55px;
    //     left: 0;
    //     width: 100%;
    //     background: white;
    //     border: 1px solid #d9d9d9;
    //     border-radius: 10px;
    //     z-index: 10;
    //     overflow-y: auto;
    //     max-height: 200px;
    //     overflow-y: scroll;
    //     &::-webkit-scrollbar {
    //       width: 6px; /* 스크롤바의 너비를 얇게 설정 (6px) */
    //     }
    //     &::-webkit-scrollbar-track {
    //       background: transparent; /* 스크롤 트랙(배경)은 투명하게 설정 */
    //     }
    //     &::-webkit-scrollbar-thumb {
    //       background-color: #d9d9d9; /* 스크롤바의 thumb 색상을 연한 회색으로 설정 */
    //       border-radius: 10px; /* 둥근 모서리 */
    //     }
    //     &::-webkit-scrollbar-thumb:hover {
    //       background-color: #b0b0b0; /* 스크롤바에 호버 시 색상을 약간 진하게 설정 */
    //     }
    //     .option-item {
    //       padding: 15px;
    //       font-size: 16px;
    //       color: #333;
    //       cursor: pointer;
    //       background: #fff;
    //       &:hover,
    //       &.selected {
    //         background-color: #f0f0f0;
    //       }
    //     }
    //   }
    // }
    > .dropdown {
      width: 25%;
      font-size: 16px;
      .dropdown-option,
      .dropdown-search {
        font-size: 16px;
        height: 45px;
        line-height: 45px;
      }
      .dropdown-header {
        border: 1px solid #d9d9d9;
      }
    }

    .year-month-button {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #343434;
        font-size: 20px;
        font-weight: 700;
      }
      button {
        background: none;
        border: none;
        width: 6px;
        height: 12px;
        svg {
          font-weight: bold;
          color: #979797;
        }
      }
    }

    .visit-legend {
      width: 30%;
      display: flex;
      gap: 17px;
      justify-content: flex-end;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          width: 17px;
          height: 17px;
          border-radius: 50%;
          display: inline-block;
        }
        p {
          color: #202224;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .visited {
        span {
          background-color: #00b69b;
        }
      }
      .not-visited {
        span {
          background-color: #f77;
        }
      }
    }
  }
  .calendar {
    position: relative;
    .calendar-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      border: 5px solid #ddd; // 회색 테두리
      border-top-color: #77c2ff; // 강조 색상
      border-radius: 50%;
      animation: spin 1s linear infinite;
      transition: opacity 0.5s ease; /* 부드럽게 나타나고 사라지도록 설정 */
    }
    .calendar-loading.show {
      opacity: 1; /* 로딩 중일 때 보이게 설정 */
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      line-height: 35px;
      background: #f8f8f8;
      color: #343434;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      .calendar-day {
        width: calc(100% / 7);
        height: 100%;
        text-align: center;
        &:not(:last-child) {
          border-right: 1px solid #f0f0f0;
        }
        &.sunday {
          color: #e50b0b;
        }
        &.saturday {
          color: #3461ff;
        }
      }
    }

    .calendar-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .calendar-cell {
      width: calc(100% / 7);
      min-height: 120px;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      background: #fff;
      padding: 2.5px;
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: #848484;

      &:nth-of-type(7n + 1) {
        border-left: 1px solid #f0f0f0;
        color: #e50b0b;
      }
      &:nth-of-type(7n) {
        color: #3461ff;
      }
      &.today {
        background: #eef7ff;
      }
    }

    .calendar-date {
      font-weight: bold;
      padding: 5px;
    }

    .calendar-reservation-list {
      display: flex;
      flex-direction: column;
      gap: 2px;
      .calendar-reservation-item {
        padding: 5px 12px;
        color: #fff;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        &.visit_status {
          background-color: #00b69b;
        }
        &.not_visit_status {
          background-color: #f77;
        }
      }
    }
  }
}
