.data-table input[type="text"] {
  width: 90% !important;
}
.hosptal_filter_temp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .filter_flex_row {
    display: flex;
    flex-direction: row;
  }
  .hospital-filter {
    position: relative;
    width: 400px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 0 10px 0 0;
  }
  .hospital-custom-select {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .year-filter {
    position: relative;
    width: 131px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-right: 8px;
  }
  .month-filter {
    position: relative;
    width: 131px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  .custom-select {
    cursor: pointer;
    width: 121px;
    height: 100%;
    border: none;
    background: none;
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      width: 55px;
    }
  }

  .dropdown-icon {
    color: #707070;
    font-size: 20px;
  }

  .custom-options {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    z-index: 10;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px; /* 스크롤바의 너비를 얇게 설정 (6px) */
    }
    &::-webkit-scrollbar-track {
      background: transparent; /* 스크롤 트랙(배경)은 투명하게 설정 */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9; /* 스크롤바의 thumb 색상을 연한 회색으로 설정 */
      border-radius: 10px; /* 둥근 모서리 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #b0b0b0; /* 스크롤바에 호버 시 색상을 약간 진하게 설정 */
    }
  }

  .option-item {
    padding: 15px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    background: #fff;
  }

  .option-item:hover,
  .option-item.selected {
    background-color: #f0f0f0;
  }

  .searchBTN {
    text-align: center;
    width: 101px;
    height: 50px;
    line-height: 50px;
    color: white;
    background-color: rgba(72, 128, 255, 1);
    border: none;
    border-radius: 3px;
  }
}
.RevlistDeleteBTN {
  height: 27px;
  line-height: 27px;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid red;
  border-radius: 3px;
  color: #ea0234;
  margin: 13px 2px;
  width: 54px;
  font-weight: 700;
}

.RevEditedMode {
  background-color: #ffffff; /* 흰색 배경 */
  color: #000000; /* 검정 텍스트 */
}
