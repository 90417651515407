// react-to-print가 scss형식을 못읽어서 adExpenseStatusPrint부분만 css로 바꿈
@import "./adExpenseStatusPrint.css";

.advertisingExpenses {
  width: 100%;
  // [광고비 입력, 광고비 히스토리] -----------------------
  .expenseInputForm,
  .expenseHistory {
    width: 100%;
    .dateFilterdeleteExpense {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .deleteBtn {
        border-radius: 5px;
        border: 1px solid #f00;
        color: #ea0234;
        font-size: 16px;
        width: 101px;
        height: 50px;
        background-color: #fff;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        background: #ededed;
        height: 50px;
      }
      tr {
        th,
        td {
          padding: 20px 15px;
          text-align: left;
          font-size: 14px;
          &:first-of-type {
            padding-left: 40px;
          }
          &:last-of-type {
            padding-right: 40px;
          }
        }
        th {
          color: #202224;
          font-weight: 500;
        }
        td {
          &:last-of-type {
            text-align: center;
          }
          &.spend_date {
            position: relative;

            input[type="text"] {
              position: absolute;
              width: calc(100% - 30px);
              left: 15px;
              z-index: 1;
            }
            input[type="date"] {
              cursor: pointer;
              width: calc(100% - 30px);
              position: relative;
              width: 100%;
              z-index: 2;
              opacity: 0;
              &::-webkit-calendar-picker-indicator {
                cursor: pointer;
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
              }
            }
          }
          color: rgba(32, 34, 36, 0.9);
          font-weight: 300;
          border-bottom: 1px solid rgba(151, 151, 151, 0.6);
          input {
            &[type="text"],
            &[type="date"] {
              border: 1px solid rgba(151, 151, 151, 0.3);
              background: rgba(208, 234, 255, 0.3);
              height: 36px;
              width: 100%;
              color: #474747;
              font-size: 14px;
              appearance: none; /* 기본 브라우저 스타일 제거 */
              -webkit-appearance: none; /* Safari 브라우저 대응 */
              padding: 0; /* 불필요한 여백 제거 */
              text-align: center;
              outline: none;
            }
          }
          button {
            width: 54px;
            height: 27px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 700;
            border: none;
            &.save-button {
              background-color: #4880ff;
              color: white;
            }
            &.edit-button {
              background-color: rgba(72, 128, 255, 0.2);
              color: #4880ff;
            }
          }
        }
      }
    }
  }
  // 날짜 검색 그룹----------
  .custom-select {
    width: 121px;
    height: 100%;
    border: none;
    background: none;
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    span {
      width: 60px;
    }
  }

  .dropdown-icon {
    color: #707070;
    font-size: 20px;
  }

  .custom-options {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    z-index: 10;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px; /* 스크롤바의 너비를 얇게 설정 (6px) */
    }
    &::-webkit-scrollbar-track {
      background: transparent; /* 스크롤 트랙(배경)은 투명하게 설정 */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9; /* 스크롤바의 thumb 색상을 연한 회색으로 설정 */
      border-radius: 10px; /* 둥근 모서리 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #b0b0b0; /* 스크롤바에 호버 시 색상을 약간 진하게 설정 */
    }
  }

  .option-item {
    padding: 15px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    background: #fff;
  }

  .option-item:hover,
  .option-item.selected {
    background-color: #f0f0f0;
  }

  /* 날짜 필터 스타일 */
  .date-filter-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .date-filter-box {
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
  }
  .date-filter {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .date-display {
    display: flex;
    align-items: center;
  }

  .date-text {
    font-size: 16px;
    color: #333;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    line-height: 50px;
    cursor: pointer;
  }

  .divider.large {
    width: 1px;
    height: 50px;
    background: #d9d9d9;
    margin: 0 15px;
  }
  .calendar-icon {
    margin: 0 -8px;
  }
  .lineSeper {
    font-size: 26px;
    color: #d9d9d9;
  }
  .arrow-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .arrow-icon {
    color: #707070;
    margin: 0px 16.5px 0 7px;
    font-size: 14px;
    cursor: pointer;
  }
  .dateRangePicker-box {
    position: absolute; /* 필요에 따라 위치 조정 */
    top: 100%; /* date-display 바로 아래 */
    left: 43%;
    z-index: 3; /* 다른 요소들 위에 표시 */
  }
  // 오른쪽 검색 그룹-----------------------------------
  .search-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 23px;
    .search_hospital {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      input {
        width: 100%;
        height: 100%;
        padding: 15px;
        font-size: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background: #e9f5ff;
      }
    }
    .search_title_box {
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 10px 20px;

      input {
        width: calc(100% - 60px);
        height: 100%;
        font-size: 16px;
        border: none;
        &:focus {
          outline: none;
        }
      }
      .search_btn {
        border-radius: 5px;
        background: #4880ff;
        color: white;
        width: 54px;
        height: 27px;
        font-size: 12px;
        font-weight: 700;
        border: none;
      }
    }
    .changeBtns {
      display: flex;
      flex-direction: column;
      gap: 12px;
      button {
        width: 100%;
        height: 60px;
        font-size: 16px;
        border: none;
        font-weight: 500;
        &.activeBtn {
          background-color: #003181;
          color: white;
        }
        &.inactive {
          background-color: white;
          border: 1px solid #003181;
          color: #003181;
        }
      }
    }
    .totalBox {
      border: 1px solid #d9d9d9;
      background: #fff;
      padding: 20px;
      h4 {
        width: 100%;
        color: #202224;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 2px solid #efefef;
        text-align: left;
        padding-bottom: 19px;
      }
      .totalValue {
        width: 100%;
        text-align: right;
        padding-top: 17px;
        color: #979797;
        font-size: 16px;
        font-weight: 500;
        span {
          color: #4880ff;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}

//[광고비 소진 현황]-----------------------

.adExpenseStatus {
  .button-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    // justify-content: space-between;
    // align-items: center;
    gap: 12px;
    button {
      height: 70px;
      font-size: 16px;
      font-weight: 500;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &.printBtn {
        width: calc(50% - (12px / 2));
        background-color: #003181;
        color: white;
        border: 1px solid #003181;
        img {
          width: 25px;
        }
      }
      &.pdfBtn {
        width: calc(50% - (12px / 2));
        background-color: white;
        color: #003181;
        border: 1px solid #003181;
        img {
          width: 20px;
        }
      }
      &.weeklyUsageButton {
        width: 100%;
        color: #ffffff;
        background-color: #4880ff;
        border: none;
        font-weight: 600;
      }
    }
  }
  .modalOverlay {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .weeklyUsageModal {
    width: 45%;
    max-width: 832px;
    border-radius: 10px;
    border: 1px solid #efefef;
    background: #fff;
    padding: 44px 150px;

    position: fixed;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
    h4 {
      color: #343434;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        background: rgba(0, 79, 198, 1);
        th {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.56px;
          width: 50%;
        }
      }
      tbody {
        td {
          color: #646464;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.48px;
        }
      }
      th,
      td {
        border: 1px solid #cfcfcf;
        height: 30px;
      }
    }
    .vat {
      color: #f00;
      text-align: right;
      font-size: 12px;
      font-weight: 500;
      margin-top: 10px;
    }
    .closeBtn {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 12px;
      right: 12px;
      background: none;
      border: none;
    }
  }
}

// 엑셀 스타일 AdExcelUpload.jsx
.adexcel-container {
}

.adexcel-input-hidden {
  display: none;
}

.adexcel-drag-drop {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 24px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.adexcel-drag-drop:hover {
  border-color: #007bff;
}

.adexcel-drag-over {
  border-color: #007bff;
}

.adexcel-text {
  font-size: 16px;
  margin: 10px 0;
  color: #979797;
}

.adexcel-title {
  text-decoration: underline;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
