.dataInputModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  .modal-content {
    width: 50%;
    aspect-ratio: 1/0.5;
    min-width: 378px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .calendar-filters {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 5% 5% 2% 5%;
    // border: 1px solid red;
    background-color: #fff;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    .calendar-title {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.38px;
      }
      span {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        text-align: right;
      }
    }
    .calendar {
      height: 80%;
      .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        line-height: 50px;
        color: rgba(60, 60, 67, 0.6);
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        .calendar-day {
          width: calc(100% / 7);
          height: 100%;
          text-align: center;
        }
      }

      .calendar-grid {
        display: flex;
        flex-wrap: wrap;
        height: calc(100% - 50px);
      }

      .calendar-cell {
        width: calc(100% / 7);
        aspect-ratio: 1/0.8;
        background: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        cursor: pointer;
        &.today {
          background: #eef7ff;
        }
        &.selected {
          background: #4880ff;
          color: #000;
          border-radius: 50% 50%;
        }
      }

      .calendar-date {
        font-family: "Poppins", sans-serif;
        padding: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        letter-spacing: -0.32px;
      }

      .calendar-reservation-list {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .calendar-reservation-item {
          padding: 5px 12px;
          color: #fff;
          font-size: 10px;
          font-weight: 500;
          cursor: pointer;
          &.visit_status {
            background-color: #00b69b;
          }
          &.not_visit_status {
            background-color: #f77;
          }
        }
      }
    }
    .year-month-button {
      margin-top: auto;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5%;
      button {
        background: none;
        border: none;
        height: 100%;
        aspect-ratio: 1/1;
        line-height: 100%;
        svg {
          font-weight: bold;
          color: #979797;
        }
      }
    }
  }
  .dataInput {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5%;
    h3 {
      color: #343434;
      font-size: 20px;
      font-weight: 700;
    }

    > .input-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 15px;
      margin-top: 15px;
      input {
        height: 50px;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        background: #e9f5ff;
        padding: 5%;
        color: #474747;
        font-size: 16px;
        font-weight: 500;
        &[type="text"] {
          width: 47.6%;
        }
        &[type="number"] {
          width: 100%;
        }
      }
    }
    .cancle-save-btns {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      button {
        width: 80px;
        height: 30px;
        border-radius: 5px;
        font-weight: 500;
        &.cancleBtn {
          border: 1px solid #d9d9d9;
          background-color: #fff;
          color: #d9d9d9;
        }
        &.saveBtn {
          background: rgba(217, 217, 217, 1);
          color: #fff;
          border: 0;
          &.active {
            background: #4880ff;
            color: #fff;
            border: 0;
          }
        }
      }
    }
  }
}
