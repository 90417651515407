.tm-main {
  .tm-data {
    .tm-data-list {
      width: 100%;

      .tmDataListHeader {
        display: flex;
        justify-content: space-between;
        .checkbox-add-columns {
          width: 37%;
          display: flex;
          flex-wrap: wrap;
          gap: 0 16px;
          color: #202224;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          label {
            display: flex;
            gap: 7px;
            align-items: center;
            cursor: pointer;
          }
        }
        .manager-selection {
          height: 50px;
          font-size: 16px;
          font-weight: 500;

          select {
            width: 150px;
            height: 100%;
            padding: 0 2.5%;
            border-radius: 10px;
            border: 1px solid #d9d9d9;
            background: #fff;
            margin-right: 8px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }
          button {
            width: 101px;
            height: 100%;
            border-radius: 5px;
            color: white;
            background: rgba(72, 128, 255, 1);
            border: none;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      // 재통화날짜버튼
      .follow-status-button {
        margin-left: 7px;
        border: none;
        line-height: 17px;
        img {
          width: 10px;
        }
      }
      // 스피너너
      .tmdata-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        font-size: 1.5em;
      }
      .tmdata-spinner::before {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        border: 0.2em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner 0.75s linear infinite;
      }

      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }
      .table-container {
        margin-top: 50px;
        overflow-x: auto;
        .tm-no-data {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          height: 400px;
          line-height: 400px;
          text-align: center;
          color: #979797;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          th {
            background: #ededed;
            color: #202224;
            font-size: 12px;
            font-weight: 500;
            height: 35px;
          }
          td {
            height: 50px;
            border-bottom: 1px solid rgba(151, 151, 151, 0.6);
            color: rgba(32, 34, 36, 0.9);
            font-size: 12px;
            font-weight: 300;
          }
          th,
          td {
            width: auto;
            min-width: 50px;
            padding-right: 20px;
            text-align: left;
            white-space: nowrap; /* 텍스트 줄바꿈 방지 */
            &:first-child {
              text-align: center;
              padding-left: 20px;
              width: 60px;
            }
            &:last-child {
              padding-right: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
  .tm-filter-panel {
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid #efefef;

    .tm-filter {
      height: 100%;
      form {
        width: 80%;
        margin: 0 auto;
        padding-top: 8%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .filter-input {
        display: flex;
        justify-content: space-between;
        height: 30px;
        label {
          width: 50%;
          line-height: 30px;
          font-size: 16px;
        }
        input,
        select {
          width: 50%;
          height: 100%;
          font-size: 12px;
          cursor: pointer;
        }
        select {
          padding: 0 2%;
          border: 1px solid rgba(151, 151, 151, 0.2);
          background: rgba(255, 255, 255, 0.2);
        }
        input {
          padding: 0 3%;
          border: 1px solid rgba(151, 151, 151, 0.2);
          background: rgba(208, 234, 255, 0.2);
        }
      }
      .form-actions {
        width: 100%;
        margin: 28px auto;
        display: flex;
        justify-content: space-between;
        button {
          font-size: 16px;
          width: 48%;
          height: 50px;
        }
        .searchBtn {
          background: #003181;
          color: #fff;
          border: 1px solid #003181;
        }
        .resetBtn {
          color: #003181;
          border: 1px solid #003181;
          background: #fff;
        }
      }
      .dbInputBtn {
        width: 125%;
        margin-left: -12.5%;
        // margin: 0 auto;
        background: #4880ff;
        color: #fff;
        border: none;
        font-size: 16px;
        font-weight: 500;
        height: 70px;
      }
    }
  }
}
