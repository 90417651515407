.alert-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;

  .alert-modal-content {
    min-width: 378px;
    padding: 25px 50px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #efefef;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .alert-modal-message {
      color: #202224;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      margin: 25px 0 20px 0;
      white-space: pre-line;
      line-height: 22px;
    }

    .alert-modal-actions {
      display: flex;
      justify-content: center;
      gap: 10px;
      .alert-modal-button {
        border: none;
        outline: none;
        font-size: 14px;
        width: 80px;
        height: 30px;
        border-radius: 5px;
        &:hover {
          opacity: 0.8;
        }
        &.confirm {
          background-color: #4880ff;
          color: #f1f1f1;
        }
        &.cancel {
          background-color: #f1f1f1;
          color: #979797;
        }
      }
    }
  }
}
