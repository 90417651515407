// DeleteBox.jsx
.delete-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* 어두운 배경 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* 다른 요소 위에 나타나도록 설정 */
  .delete-box {
    background-color: white;
    width: 378px;
    height: 192px;
    border-radius: 10px;
    text-align: center;
  }

  .delete-box-icon {
    background-color: #ffe9e9;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 27px auto;
    color: #ea0234;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .delete-box p {
    color: #202224;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 17px;
  }

  .delete-box-buttons {
    display: flex;
    justify-content: center;
  }

  .cancel-button,
  .confirm-button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    margin: 0 5px;
  }

  .cancel-button {
    background-color: white;
    border: 1px solid #ccc;
    color: #979797;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    background-color: #4880ff;
    color: white;
  }

  .cancel-button:hover {
    background-color: #f0f0f0;
  }

  .confirm-button:hover {
    background-color: #3566cc;
  }
}
// AlertBox.jsx
.alert-box-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* 어두운 배경 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* 다른 요소 위에 나타나도록 설정 */
  .alert-box {
    background-color: white;
    width: 378px;
    height: 192px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .alert-box-icon {
    background-color: #ffe9e9;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 27px auto;
    color: #ea0234;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .alert-box p {
    color: #202224;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 17px;
  }

  .alert-box-button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: #4880ff;
    color: white;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .alert-box-button button {
    border: none;
    background: none;
    color: white;
  }

  .alert-box-button:hover {
    background-color: #3566cc;
  }
}

// TabComponent.jsx
.listContainer {
  background-color: #f5f6fa;

  .TabSetting {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    height: 204px;
  }

  .tab {
    width: 20.8vw;
    height: 60px;
    flex-grow: 1;
    margin-bottom: 12px;
    cursor: pointer;
    color: white;
    background-color: #ffffff;
    color: #003181;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #003181;
    border-radius: 8px;
  }

  .tab.active {
    background-color: #003181;
    color: #ffffff;
  }
  .listPostBtn {
    width: 240px;
    height: 50px;
    margin: 0 auto;
    align-items: center;
    background-color: #4880ff;
    color: white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    border: none;
    border-radius: 5px;
    margin-top: 25px;
  }

  .listTitleHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
  }
  .listTitle {
    color: #343434;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
  }
  .listDeleteBTN {
    border-radius: 5px;
    border: 1px solid #f00;
    color: #ea0234;
    font-size: 16px;
    width: 101px;
    height: 50px;
    background-color: #fff;
  }
  .postForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 940px;
    justify-content: space-between;
    label {
      color: #343434;
      font-size: 16px;
      font-weight: 500;
      height: 50px;
      line-height: 50px;
    }
  }
  .listInput {
    margin-left: 14px;
    width: 19.791vw;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: var(--White, #fff);
    outline: none;
    padding: 0 21px;
    font-size: 16px;
    line-height: 50px;
    text-align: left;
  }
  .longInput {
    width: 44.41vw;
  }
  .longInput2 {
    width: 45.13vw;
  }

  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  .data-table th,
  .data-table td {
    text-align: left;
    font-size: 14px;
  }
  .data-table td {
    height: 60px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.6);
  }
  .data-table th {
    background-color: #ededed;
    font-weight: 600;
    text-align: left;
    padding: 16px 0 15px;
  }

  .data-table input[type="checkbox"] {
    width: 17px;
    height: 17px;
  }
  .data-table input[type="text"] {
    width: 72px;
    height: 36px;
    padding: 9px;
    margin: 14px 2px;
    background-color: #d0eaff33;
    border: 1px solid #979797;
    outline: none;
    text-align: center;
    // color: rgba(32, 34, 36, 0.3);
    border-radius: 2px;
  }

  .editInputColor input,
  .editInputColor button {
    background-color: #f8f4f4; /* input이나 button의 백그라운드 색상 */
  }
  .listSaveBTN {
    width: 54px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    border-radius: 3px;
    background-color: rgba(72, 128, 255, 0.2);
    color: #4880ff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    margin: 14px 2px;
  }
  .editInputColor {
    color: #cfcfcf;
  }
  .normalSpan {
    padding: 0 0 0 21px;
  }
  .selected {
    background-color: #d0eaff;
  }
  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;
    }
    .tab {
      margin-bottom: 10px;
    }
    .postForm {
      width: 100%;
      padding: 0 20px;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination button {
    background-color: white;
    border: none;
    color: #979797;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 12.5px;
  }
  .doubleArrow {
    width: 11px;
    height: 12px;
  }
  .singleArrow {
    width: 6px;
    height: 12px;
  }
  .pagination button.currentPage {
    color: #202224;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  .pagination button:hover {
    background-color: #f0f0f0;
  }
}
//CustomDropdown.jsx
.dropdown {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    line-height: 100%;
    padding: 0 10px 0 21px;
    border: 1px solid #4880ff;
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
  }

  .dropdown-arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    margin: 0px 7px 6px;
  }

  .dropdown-options {
    max-height: 348px;
    position: absolute;
    top: 105%;
    left: 0;
    width: 100%;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 10px;

      &:hover {
        background-color: #b0b0b0;
      }
    }
  }
  .dropdownCheckBox {
    margin: 0 10px;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%; /* 적절한 너비 지정 */
    text-align: left;
  }

  .dropdown-search {
    width: calc(100% - 14px);
    margin: 0 7px;
    padding: 0 10px;
    border: none;
    border-bottom: 1px solid #f1f1f1;
    font-size: 12px;
    outline: none;
  }

  .dropdown-option {
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    text-align: center;
    color: #979797;
    font-size: 12px;

    &.selected {
      background-color: #4880ff;
      color: white;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .dropdown-option-set {
    height: 56px;
    line-height: 56px;
    text-align: start;
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    color: #979797;
    font-size: 16px;
    margin: 4px 7px;
    border-radius: 5px;

    &:hover {
      background-color: #4880ff;
      color: white !important;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &.big-dropdown {
    .dropdown-header {
      border-radius: 10px;
    }
    .dropdown-search {
      font-size: 16px;
      height: 56px;
      line-height: 56px;
    }
  }
}
